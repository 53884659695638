@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/mxo8bvz.css");
@import url("https://use.typekit.net/wgy7mbh.css");


@layer base {
    body{
        font-family: 'elza', sans-serif;
        font-weight: 400;
        @apply bg-myGreen text-myBlack w-full h-[100vh];


    }

    h2{
        font-weight: 500;
    }
}

@layer components{

    .links-hover{
        @apply text-myGrey;
        transition: 0.3s;
    }

    .services-hover{
        @apply text-myGrey;
        transition: 0.3s;

    }

    .hover-preorder{
        @apply text-myGrey;
        transition: 0.3s;
    }

    .box{
        padding: 10px;
        border: solid black 1px;
/*             @media screen and (min-width: 800px) {
                &:hover{
                    @apply bg-myBlack;
                    transition: 0.3s;
            }
        } */
    }


    .credits-container{
        position: absolute;
        top: 50%;
        right: 0;
        padding-right: 30px;
    }

   



}